import React, { useCallback, useMemo, useState } from "react"
import { useForm } from 'reactHookFormLatest'
import LoaderButton from 'components/shared/LoaderButton'
import ShareDocumentLabelAndSearchBar from "components/shared/ShareDocumentLabelAndSearchBar"

import ActionSelector from './ActionSelector'
import AllRowSelect from "../../ShareDocuments/AllRowSelect"
import Modal from '../../Modal'
import ResourceRow from '../../ShareDocuments/ResourceRow'
import ShareResult from './ShareResult'
import utils from '../../utils/utils'

import { removeEnvelope } from 'components/Packages/Package/utils'

const ShareDocumentModalForm = ({
  action: initialAction = 'bind_document_with_agreement',
  agreementResources,
  availableAgreementResources = [],
  cancel,
  documentResources,
  editPackage = false,
  formOptions = {},
  onDone = () => {},
  onSave = () => {},
  packageData,
  planAllowsLabels,
  step: initialStep = 'actionSelect',
}) => {
  // DEFAULT STATES
  const { register, handleSubmit, errors, formState, setValue, getValues, watch } = useForm(formOptions)
  const [action, setAction] = useState(initialAction)
  const [error, setError] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [sentData, setSentData] = useState(false)
  const [step, setStep] = useState(initialStep)
  const [selectedLabelIds, setSelectedLabelIds] = useState(['all'])
  const [documentSearchValue, setDocumentSearchValue] = useState(null)

  const agreementResourceIds = watch('agreement_resource_ids')
  const documentResourceIds = watch('document_resource_ids')

  // FUNCTIONS
  const hasSelectedDocuments = useMemo(() => {
    const values = getValues()
    return values['document_resource_ids']?.length > 0
  }, [agreementResourceIds, documentResourceIds])

  const hasSelectedAgreements = useMemo(() => {
    const values = getValues()
    return values['agreement_resource_ids']?.length > 0
  }, [agreementResourceIds, documentResourceIds])

  const formIsValid = useMemo(() => {
    if (action == 'send_agreement') {
      return hasSelectedAgreements
    } else if (action == 'send_document') {
      return hasSelectedDocuments
    } else {
      return hasSelectedAgreements && hasSelectedDocuments
    }
  }, [action, hasSelectedAgreements, hasSelectedDocuments])

  const shouldSubmitForm = useMemo(() => {
    if (action == 'send_document') {
      return step == 'documents'
    } else {
      return step == 'agreements'
    }
  }, [action, step])

  const stepIsValid = useMemo(() => {
    if (step === 'actionSelect') {
      return action !== ''
    }
    if (step === 'documents') {
      return hasSelectedDocuments
    }
    if (step === 'agreements') {
      return hasSelectedAgreements
    }
  }, [step, action, hasSelectedDocuments, hasSelectedAgreements])

  const hasDataToProgress = useMemo(() => {
    if (!documentResources || !agreementResources) {
      return false
    }

    if (step === 'actionSelect') {
      return documentResources.length > 0 || availableAgreementResources.length > 0
    }

    if (action == 'send_agreement') {
      return agreementResources.length > 0
    } else if (action == 'send_document') {
      return documentResources.length > 0
    } else {
      return documentResources.length > 0 && agreementResources.length > 0
    }
  }, [step, action, documentResources, availableAgreementResources, agreementResources])

  const checkboxSelected = (field) => {
    const values = getValues()
    const hasError = values[field] && (values[field].length == 0 || !values[field])
    const error = hasError ? errorMessage() : ''
    setError(error)
  }

  const errorMessage = () => {
    return `Select at least one ${step.slice(0, -1)} to add to the envelope`
  }

  const popupTitle = useMemo(() => {
    switch (step) {
      case 'actionSelect':
        if ((!documentList || documentList.length == 0) && (!availableAgreementResources || availableAgreementResources.length == 0)) {
          return 'No Files'
        }
        return 'Add Envelope'
      case 'documents':
        return 'Choose Documents'
      case 'agreements':
        return 'Choose Agreements'
      case 'done':
        return 'Added!'
      case 'error':
        return 'Error'
      default:
        return ''
    }
  }, [step, documentList, availableAgreementResources])

  const popupContent = () => {
    switch (step) {
      case 'actionSelect':
        return <p>Choose what you'll add to the envelope:</p>
      case 'documents':
        return !planAllowsLabels ? <p>Next, choose documents to add to the envelope:</p> : null
      case 'agreements':
        return <p>Next, choose agreements to add to the envelope:</p>
      default:
        return false
    }
  }

  const nextStep = useMemo(() => {
    if (action == 'send_agreement') {
      if (step == 'actionSelect') {
        return 'agreements'
      }
    }
    else if (action == 'send_document') {
      if (step == 'actionSelect') {
        return 'documents'
      }
      if (step == 'documents') {
        return 'done'
      }
    } else {
      if (step == 'actionSelect') {
        return 'documents'
      }
      if (step == 'documents') {
        return 'agreements'
      }
      if (step == 'agreements') {
        return 'done'
      }
    }
    if (step == 'done') {
      return
    }
  }, [action, step])

  const previousStep = useMemo(() => {
    if (action == 'send_agreement') {
      if (step == 'agreements') {
        return 'actionSelect'
      }
    } else if (action == 'send_document') {
      if (step == 'documents') {
        return 'actionSelect'
      }
    } else {
      if (step == 'documents') {
        return 'actionSelect'
      }
      if (step == 'agreements') {
        return 'documents'
      }
    }
  }, [action, step])

  const stepForward = useCallback(() => {
    if (stepIsValid) {
      setStep(nextStep)
      setError(false)
    } else {
      setError(errorMessage())
    }
  }, [action, step, stepIsValid, nextStep])

  const shouldDisplayBackButton = useMemo(() => {
    if (editPackage && step === 'documents') {
      return false
    }

    return step !== 'actionSelect' && step !== 'done' && step !== 'error'
  }, [step])

  const errorContent = (title, content) => {
    return <div className="column">
      <div className="has-text-centered" style={{ paddingTop: '80px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72">
          <path fill="#FADB47" d="M36,0 C55.882251,0 72,16.117749 72,36 C72,55.882251 55.882251,72 36,72 C16.117749,72 0,55.882251 0,36 C0,16.117749 16.117749,0 36,0 Z M36,4 C18.326888,4 4,18.326888 4,36 C4,53.673112 18.326888,68 36,68 C53.673112,68 68,53.673112 68,36 C68,18.326888 53.673112,4 36,4 Z M38,49 L38,53 L34,53 L34,49 L38,49 Z M38,19 L38,45 L34,45 L34,19 L38,19 Z" />
        </svg>
        <h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>{title}</h2>
        <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '12px 16px', maxWidth: '500px', marginTop: '40px' }}>
          {content}
        </p>
      </div>
    </div>
  }

  const stepBack = useCallback(() => {
    if (step == 'actionSelect') {
      return
    }

    resetStepSelection(step)
    setStep(previousStep)
    setError(false)
  }, [step, action, previousStep])

  const resetStepSelection = (step) => {
    if (step == 'documents') {
      if (editPackage) {
        setValue('document_resource_ids', formOptions.defaultValues['document_resource_ids'])
      } else {
        setValue('document_resource_ids', [])
      }
    }
    if (step == 'agreements') {
      if (editPackage) {
        setValue('agreement_resource_ids', formOptions.defaultValues['agreement_resource_ids'])
      } else {
        setValue('agreement_resource_ids', [])
      }
    }
  }

  const fieldsBasedOnCurrentAction = () => {
    switch (action) {
      case 'send_agreement':
        return ["agreement_resource_ids"]
      case 'send_document':
        return ["document_resource_ids"]
      case 'bind_document_with_agreement':
        return ["agreement_resource_ids", "document_resource_ids"]
      default:
        return []
    }
  }

  const createEnvelope = ({ packageSlug, data }) => {
    fetch(`/packages/${packageSlug}/add_envelope`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }).then(res => {
      onSave()
      setIsSending(false)
      setSentData(data)
      setStep('done')
    }).catch(err => {
      setIsSending(false)
      setStep('error')
    })
  }

  const submitData = (data) => {
    setIsSending(true)

    if (!formIsValid) {
      setIsSending(false)
      return
    }

    const fields = fieldsBasedOnCurrentAction()
    const values = getValues()

    for (var i = 0; i < fields.length; i++) {
      const field = fields[i]
      if (values[field] && (values[field].length == 0 || !values[field])) {
        setError(errorMessage())
        return
      }
      // force array values
      data[field] = Array.isArray(data[field]) ? data[field] : [data[field]]
    }

    if (editPackage) {
      removeEnvelope({
        agreementResourceIds: formOptions.defaultValues['agreement_resource_ids'],
        documentResourceIds: formOptions.defaultValues['document_resource_ids'],
        packageSlug: packageData.slug,
      }).then(() => {
        createEnvelope({ packageSlug: packageData.slug, data: data })
      })
    } else {
      createEnvelope({ packageSlug: packageData.slug, data: data })
    }
  }

  // LOGIC BEGINS

  // build document list
  const documentList = useMemo(() => {
    if (!documentResources) {
      return []
    }

    return documentResources.filter(document => {
      if (!documentSearchValue) return true
  
      return document.name.toUpperCase().includes(documentSearchValue.toUpperCase())
    }).sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }).map((documentResource, index) => {
      if (selectedLabelIds[0] !== 'all') {
        if (documentResource.label_ids && documentResource.label_ids.filter(label_id => selectedLabelIds.indexOf(label_id) > -1).length === 0) {
          return
        }
      }

      return <ResourceRow
        key={`document-resource-${index}`}
        name="document_resource_ids"
        onResourceSelected={(documentResourceId) => checkboxSelected('document_resource_ids')}
        resource={documentResource}
        resourceType="document"
        register={register} />
    })
  }, [documentResources, step, selectedLabelIds, documentSearchValue])

  const agreementList = useMemo(() => {
    if (!agreementResources) {
      return []
    }

    return agreementResources.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }).map((agreementResource, index) => {
      return <ResourceRow
        key={`agreement-resource-${index}`}
        resource={agreementResource}
        resourceType="agreement"
        name="agreement_resource_ids"
        onResourceSelected={(agreementResourceId) => checkboxSelected('agreement_resource_ids')}
        register={register} />
    })
  }, [agreementResources, step])

  const availableAgreementList = useMemo(() => {
    if (!availableAgreementResources) {
      return []
    }

    return availableAgreementResources.map((agreementResource, index) => (
      <ResourceRow
        key={`available-agreement-resource-${index}`}
        resource={agreementResource}
        resourceType="agreement"
        name="agreement_resource_ids"
        onResourceSelected={(agreementResourceId) => checkboxSelected('agreement_resource_ids')}
        register={register} />
    ))
  }, [availableAgreementResources, step])

  const shareResult = useMemo(() => {
    if (!sentData) {
      return
    }

    return <div style={{ visibility: step == 'done' ? 'visible' : 'hidden', position: step == 'done' ? 'relative' : 'absolute', top: step == 'done' ? 'auto' : '-1000px' }}>
      <ShareResult
        agreements={agreementResources.filter((agreementResource) => (sentData['agreement_resource_ids'] && sentData['agreement_resource_ids'].indexOf(agreementResource.id.toString()) > -1))}
        documents={documentResources.filter((documentResource) => (sentData['document_resource_ids'] && sentData['document_resource_ids'].indexOf(documentResource.id.toString()) > -1))}
        packageData={packageData} />
    </div>
  }, [sentData, step])

  const documentListDom = useMemo(() => {
    const values = getValues()

    return <ul
      className="documents"
      style={{ visibility: step == 'documents' ? 'visible' : 'hidden', position: step == 'documents' ? 'relative' : 'absolute', top: step == 'documents' ? 'auto' : '-1000px' }}>
      {documentList.length > 0 && <AllRowSelect
        checked={(values['document_resource_ids'] ? (Array.isArray(values['document_resource_ids']) ? values['document_resource_ids'] : [values['document_resource_ids']]) : []).length === documentResources.map(d => d.id.toString()).length && (values['document_resource_ids'] ? (Array.isArray(values['document_resource_ids']) ? values['document_resource_ids'] : [values['document_resource_ids']]) : []).every((value, index) => value === documentResources.map(d => d.id.toString())[index])}
        indeterminate={(values['document_resource_ids'] || []).length > 0}
        onChange={() => { checkboxSelected('document_resource_ids') }}
        resourceList={documentList}
        resourceType="document" />}
      {documentList}
    </ul>
  }, [agreementResourceIds, documentResourceIds, step, documentList])

  const agreementListDom = useMemo(() => {
    return <ul className="agreements"
      style={{ visibility: step == 'agreements' ? 'visible' : 'hidden', position: step == 'agreements' ? 'relative' : 'absolute', top: step == 'agreements' ? 'auto' : '-1000px' }}>
      {(action == 'send_agreement') ? availableAgreementList : agreementList}
    </ul>
  }, [action, step, availableAgreementList, agreementList])

  const actionSelect = useMemo(() => {
    if (!hasDataToProgress) {
      return
    }

    return <ActionSelector
      availableAgreementResources={availableAgreementResources}
      agreementResources={agreementResources}
      documentResources={documentResources}
      key="actionSelector"
      innerStyle={{ visibility: step == 'actionSelect' ? 'visible' : 'hidden', position: step == 'actionSelect' ? 'relative' : 'absolute', top: step == 'actionSelect' ? 'auto' : '-1000px' }}
      onChange={(action) => setAction(action)} />
  }, [step])

  const saveButton = useMemo(() => {
    let button
    if (shouldSubmitForm) {
      button = isSending ? <LoaderButton width="140px" /> : <input className={`button is-info ${formIsValid ? '' : 'disabled'}`}
        draggable="false"
        type="submit"
        style={{ width: editPackage ? "auto" : '140px' }}
        value={editPackage ? "Update" : "Add Envelope"} />
    } else {
      if (documentResources.length > 0 || agreementResources.length > 0) {
        button = <a className={`button is-info ${stepIsValid ? '' : 'disabled'}`}
          draggable="false"
          onClick={() => stepForward()}>Next</a>
      } else {
        button = false
      }
    }

    if (step === 'done') {
      button = <a className="button is-info"
        draggable="false"
        onClick={() => onDone()}>Done</a>
    } else if (step === 'error') {
      button = <a className="button is-info"
        draggable="false"
        onClick={() => onDone()}>Close</a>
    } else if (documentList.length == 0 && availableAgreementResources.length == 0) {
      button = <a className="button" onClick={cancel}>Close</a>
    }

    return button
  }, [shouldSubmitForm, isSending, step, stepIsValid, formIsValid, documentList, availableAgreementResources, documentResources, agreementResources, action, nextStep])

  const cancelButton = useMemo(() => {
    if (step === 'done') {
      return true
    } else if (step === 'error') {
      return true
    } else if (hasDataToProgress) {
      return <a className="button is-white" onClick={cancel}>Cancel</a>
    } else if (documentList.length == 0 && availableAgreementResources.length == 0) {
      return true
    }
  }, [step, hasDataToProgress, documentList, availableAgreementResources])

  const content = useMemo(() => {
    if (step === 'error') {
      return errorContent('Could not send this envelope', 'Please try again later...')
    } else if (hasDataToProgress) {
      return popupContent()
    } else if (documentList.length == 0 && availableAgreementResources.length == 0) {
      return errorContent('Add Documents or Agreements', `Add at least one Document or Agreement to contribute to this package.`)
    }
  }, [step, hasDataToProgress, documentList, availableAgreementResources])

  const errorElement = useMemo(() => {
    if (!error) {
      return
    }
    return <label className="error">{error}</label>
  }, [error])

  const backButton = useMemo(() => {
    if (shouldDisplayBackButton) {
      return <a className="button"
        draggable="false"
        onClick={() => stepBack()}
        style={{ paddingLeft: '12px', position: 'absolute', left: '40px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <g fill="none" fillRule="evenodd">
            <path fill="#F4F5F5" d="M-633-21H807v1470H-633z" />
            <path fill="#FFF" d="M-32-21h839v60H-32z" />
            <g transform="translate(-12 -9)">
              <rect width="89" height="35" x=".5" y=".5" stroke="#CED0D2" rx="6" />
              <path fill="#424C53" d="M27 17.25h-9.128l4.193-4.192L21 12l-6 6 6 6 1.057-1.057-4.185-4.193H27z" />
            </g>
          </g>
        </svg>
        <span style={{ paddingLeft: '10px' }}>Back</span>
      </a>
    }
  }, [shouldDisplayBackButton, step, action, previousStep])

  const labelList = useMemo(() => {
    if(planAllowsLabels && step === 'documents') {
      return <ShareDocumentLabelAndSearchBar
              selectedLabelIds={selectedLabelIds}
              onLabelSelectionChange={labels => setSelectedLabelIds(labels)}
              onSearchValueChange={value => setDocumentSearchValue(value)} />
    }
  }, [planAllowsLabels, step, selectedLabelIds])

  return <form onSubmit={handleSubmit(submitData)}>
    <Modal
      cancelAction={step === 'done' ? onDone : cancel}
      cancelButton={cancelButton}
      confirmButton={saveButton}
      deleteButton={backButton}
      title={popupTitle}
      modalCardStyle={{ height: '600px', minWidth: '750px', overflowX: 'hidden' }}
      modalCardBodyStyle={{ padding: 0, overflowX: 'hidden' }}>
      <div className="content">
        <div className="columns">
          <div className="column document-list">
            {content}
            {actionSelect}
            {labelList}
            {documentListDom}
            {agreementListDom}
            {shareResult}
            {errorElement && step !== 'documents' && <p style={{ color: '#d92022', fontSize: '14px' }}>{errorElement}</p>}
          </div>
        </div>
      </div>
    </Modal>
  </form>
}

export default ShareDocumentModalForm
