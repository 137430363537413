import React, { useEffect, useRef, useState } from "react"

import LabelList from 'components/LabelList'
import SearchBox from "components/shared/SearchBox"

import utils from '../utils/utils'

const ShareDocumentLabelAndSearchBar = ({
  selectedLabelIds,
  onLabelSelectionChange = () => {},
  onSearchValueChange = () => {},
}) => {
  const searchBoxWrapper = useRef(null)
  const [labelFilterRight, setLabelFilterRight] = useState(0)
  const [searchBoxExpanded, setSearchBoxExpanded] = useState(false)

  const handleResize = () => {  
    if (searchBoxWrapper.current) {
      const newSearchBoxWrapperWidth = utils.widthOfDiv(searchBoxWrapper.current)
      if (newSearchBoxWrapperWidth !== labelFilterRight) {
        setLabelFilterRight(newSearchBoxWrapperWidth)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return <div style={{ position: 'relative', left: '-20px', width: 'calc(100% + 20px)' }}>
    <LabelList
      addAllOption={true}
      onLabelSelectionChange={labels => onLabelSelectionChange(labels)}
      right={labelFilterRight}
      searchBoxExpanded={searchBoxExpanded}
      selectedLabelIds={selectedLabelIds}
      withBorderBottom={true} />
    <div style={{ position: 'absolute', right: 0 }} ref={searchBoxWrapper}>
      <SearchBox 
        largeBox={true}
        onChange={value => onSearchValueChange(value)} 
        onSearchExpendChange={expanded => {
          setSearchBoxExpanded(expanded)
          handleResize()
        }} />
    </div>
    <br/>
    <br/>
  </div>
}

export default ShareDocumentLabelAndSearchBar
